import moment from "moment"
import React from "react"

export const UpcomingActivity = ({ event }) => {
    const formattedStartTime = event.startTime && moment(event.startTime).format("LT")
    const formattedEndTime = event.endTime && moment(event.endTime).format("LT")
    const startDate =
        event.startTime &&
        moment(event.startTime).format("dddd, MMMM Do YYYY") +
        ` at ${formattedStartTime}`

    return (

        <div className="upcoming-activity">
            <div className="upcoming-activity__image"></div>
            <div className="upcoming-activity__content">
                <div className="upcoming-activity__title">{event.name}</div>
                <div className="upcoming-activity__start-day">{startDate}</div>

                <div className="upcoming-activity__description">
                    {event.description}
                </div>

                <div className="upcoming-activity__time-row">
                    <div>{formattedStartTime}</div>
                    <div>{formattedEndTime || "—"}</div>
                </div>
            </div>
        </div>

    )
}

export const Activity = ({ name, subtitle, description, startTime, endTime, image = "" }) => {

    const backgroundStyle = image ? {
        "background": `url(${image})`,
    } : {}

    return (

        <div className="upcoming-activity">
            <div className="upcoming-activity__image" style={backgroundStyle}></div>
            <div className="upcoming-activity__content">
                <div className="upcoming-activity__title">{name}</div>
                <div className="upcoming-activity__start-day">{subtitle}</div>

                <div className="upcoming-activity__description">
                    {description}
                </div>

                <div className="upcoming-activity__time-row">
                    <div>{startTime}</div>
                    <div>{endTime || "—"}</div>
                </div>
            </div>
        </div>

    )
}