import React from "react"
import { Container, Paper } from "@material-ui/core"

import ContactUsSection from "../components/sections/ContactUsSection"
import Hero from "../components/sections/Hero"
import Navbar from "../components/sections/Navbar"

const BasicTemplate = ({ children, title }) => {
  return (
    <div className="basic-template">
      <Navbar />
      <Hero title={title} />
      <Container size="lg" className="basic-template__content-wrapper">
        <Paper elevation={0}>
          <div className="basic-template__content-container">{children}</div>
        </Paper>
      </Container>
      <ContactUsSection />
    </div>
  )
}

export default BasicTemplate
