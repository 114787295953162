import React, { useEffect, useState } from "react"
import { Grid, IconButton, Typography } from "@material-ui/core"

import SEO from "../components/seo"
import BasicTemplate from "../components/BasicTemplate"
import { FacebookService } from "../services/facebook.service"
import { CenteredTextBlock } from "../components/blocks/CenteredTextBlock"
import FacebookIcon from "@material-ui/icons/Facebook"
import ShadowImage from "../components/shadowImage"
import { Loader } from "../components/Loader"
import { UpcomingActivity } from "../components/Activity"
import { PAGE_URLS } from "../enum/PageUrls"
import { Link } from "gatsby"
import { config } from "../../config"

const STATUS = {
  LOADING: true,
  FINISHED: false,
  NOT_STARTED: null,
}

const UpcomingActivitiesPage = () => {
  const [events, setEvents] = useState([])
  const [loading, setIsLoading] = useState(STATUS.NOT_STARTED)

  const hasNoEvents = loading === STATUS.FINISHED && events.length === 0
  const hasEvents = loading === STATUS.FINISHED && events.length > 0
  const isLoading = loading === STATUS.LOADING

  useEffect(() => {
    async function loadEvents() {
      setIsLoading(true)
      const facebookService = new FacebookService()
      const events = await facebookService.getEvents()
      setIsLoading(false)
      setEvents(events)
    }
    loadEvents()
  }, [])

  return (
    <BasicTemplate title="Upcoming Activities">
      <div className="upcoming-activites-page">
        <SEO
          title="Upcoming Activities"
          description="See Our Upcoming Events!"
        />

        {isLoading && <Loader />}

        {hasEvents && (
          <Grid container spacing={5}>
            <div className="events-block">
              <CenteredTextBlock title="Upcoming Activities">
                Besides our{" "}
                <Link className="red-link" to={PAGE_URLS.ACTIVITIES}>
                  weekly events
                </Link>
                , we often host fun events open to the public! Check in here
                frequently or{" "}
                <a
                  className="red-link"
                  href={config.facebookLink}
                  target="_blank"
                >
                  check out our facebook page
                </a>{" "}
                to learn more about upcoming events!
              </CenteredTextBlock>
            </div>
            {events.map((event, i) => (
              <UpcomingActivity event={event} key={i} />
            ))}
          </Grid>
        )}

        {hasNoEvents && (
          <div className="no-events-block">
            <CenteredTextBlock title="No Upcoming Activites">
              <div className="facebook-tag">
                <IconButton edge="start" color="inherit" aria-label="menu">
                  <FacebookIcon />
                </IconButton>
                <Typography variant="body2">
                  <a
                    className="body-link"
                    href={config.facebookLink}
                    target="_blank"
                  >
                    Check out our facebook page to learn more about upcoming
                    events!
                  </a>
                </Typography>
              </div>{" "}
            </CenteredTextBlock>

            <div className="text-center">
              <ShadowImage imageUrl="https://i.imgur.com/6Zag0YJ.jpg" />
            </div>
          </div>
        )}
      </div>
    </BasicTemplate>
  )
}

export default UpcomingActivitiesPage
