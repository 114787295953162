import React from "react"
import { Container, Typography, Button, Grid } from "@material-ui/core"
import { useButtonStyles } from "../../styles/styles"
import { Link } from "gatsby"

export const CenteredTextBlock = ({
  title = "What Are We About?",
  children = "We are here to host your wedding receptions, fundraisers, and any other events! Come grab a drink at our bar!",
  buttonLink,
  buttonText = "Button",
  large,
  style = {}
}) => {
  const classes = useButtonStyles()
  const largeClass = large ? "large" : ""

  return (
    <div className={`centered-text-block ${largeClass}`} style={style}>
      <Container maxWidth="lg">
        <Typography variant="h4" className="lined-title">
          {title}
        </Typography>
        <Typography variant="body1" paragraph className="mt-2 m-auto">
          {children}
        </Typography>
        {buttonLink && (
          <Link>
            <Button style={{ marginTop: 30 }} className={classes.primaryButton}>
              {buttonText}
            </Button>
          </Link>
        )}
      </Container>
    </div>
  )
}
