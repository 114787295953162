import React from "react"

const ShadowImage = ({imageUrl = "https://i.imgur.com/BMiGRth.jpg", altText="Image", title="Title", style}) => {

const styles = {
    backgroundImage: `url(${imageUrl})`,

}
  return (
    <div className="shadow-image" style={style}>
      <div className="shadow-image__shadow" style={styles}></div>
      <img className="shadow-image__main" src={imageUrl} alt={altText} title={altText} />
    </div>
  )
}

export default ShadowImage
