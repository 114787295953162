import { EventModel } from "./event.model"
import moment from "moment"

const url = "https://graph.facebook.com/v6.0/KnightsOfColumbus4239"
// TODO: export
const access_token =
  "EAAHp88ZBpsBQBAAZCmxG2SzCy6xcI9Szo54e2gZCyRSZBqedzDK39ZCv5VjZBOosXn6LZBjY4xZC4KTxIZB04wrvQ8ThPW2St1fqTZARtuv8PutVeK1YoaFk6rPj0IPsQC2Y6jLxxGobjT1AAHiFyDpZArhqTWh9HnEVHCeiGfSFTVdzgZDZD"

export class FacebookService {

  async getEvents() {
    const eventsUrl = url + "/events" + "?access_token=" + access_token

    if (this.shouldNotFetchEventsFromFacebook()) {
      return this.getEventsFromStorage()
    }
    
    try {
      const response = await fetch(eventsUrl)
      const data = await response.json()

      const events = data.data || []

      const mappedEvents = []

      events.forEach(event => {
        const parentEvent = new EventModel(
          event.name,
          event.description,
          event.start_time,
          event.end_time,
          (event.event_times || []).map(
            y =>
              new EventModel(
                event.name,
                event.description,
                y.start_time,
                y.endTime,
                []
              )
          )
        )

        // push event
        mappedEvents.push(parentEvent)

        // push any grouped events
        if (parentEvent.allTimes) {
          parentEvent.allTimes.forEach(subevent => mappedEvents.push(subevent))
        }
      })

      const onlyEventsForTodayAndFuture = this.getTodayAndFutureEvents(
        mappedEvents
      )
      const sortedEvents = this.sortByDate(onlyEventsForTodayAndFuture)

      this.saveEventsToStorage(sortedEvents)

      return sortedEvents
    } catch (e) {
      return this.getEventsFromStorage() || []
    }
  }

  getEventsFromStorage() {
    return JSON.parse(localStorage.getItem("events"))
  }

  saveEventsToStorage(events) {
    localStorage.setItem("timestamp",  moment())
    localStorage.setItem("events", JSON.stringify(events))
  }

  getTodayAndFutureEvents(events) {
    return events.filter(a => {
      const year = a.startTime.substring(0, 4)
      const month = a.startTime.substring(5, 7)
      const date = a.startTime.substring(8, 10)
      const today = moment()
      const targetDate = moment(new Date(`${month}/${date}/${year}`))
      return today.diff(targetDate, "days") <= 0
    })
  }

  sortByDate(events) {
    return events.sort((a, b) => {
      const aTime = new Date(a.startTime)
      const bTime = new Date(b.startTime)
      if (aTime > bTime) {
        return 1
      } else if (bTime > aTime) {
        return -1
      } else {
        return 0
      }
    })
  }

  // should fetch if haven't fetched today
  shouldNotFetchEventsFromFacebook() {
    const events = this.getEventsFromStorage()
    const timestamp =  localStorage.getItem("timestamp")

    if (!events) return false
    if (!timestamp) return false

    const today = moment()
    return today.isSame(timestamp, "d")
  }
}
