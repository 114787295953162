export class EventModel {
    constructor(name, description, startTime, endTime, allTimes = [], image = "") {
        this.name = name;
        this.description = description;
        this.startTime = startTime;
        this.endTime = endTime;
        this.allTimes = allTimes;
        this.image = image;
    }
}
